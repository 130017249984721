import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image1 from "../../images/blog23/image1.jpg"
import image2 from "../../images/blog23/image2.jpg"
import image3 from "../../images/blog23/image3.png"
import image4 from "../../images/blog23/image4.jpg"
import image5 from "../../images/blog23/image5.jpg"
import image6 from "../../images/blog23/image6.jpg"
import image7 from "../../images/blog23/image7.jpg"
import image8 from "../../images/blog23/image8.jpg"
import image9 from "../../images/blog23/image9.jpg"
import image10 from "../../images/blog23/image10.jpg"

export default function FiveReasonsYouShouldResistanceTrain(){
    return(
        <BlogPostOld
            src={image9}
            title={"5 Reasons You Should Resistance Train"}
            summary={"If all of the benefits of muscle strengthening through resistance training were packed into a pill - there would be a line out the door for people to buy it. However, there are no monetary vested interests or massive profits to be had on the scale of pharmaceuticals or charging insurances to treat illness to pump awareness."}
            date={"September 12, 2022"}
            author={"Dr. Donald Mull, DC"}
            InStyle={"max-h-full"}
        >
            <>
                <p style={{ textAlign: "center" }}>
    <span style={{ textDecoration: "underline" }}>
      <strong>5 Reasons You Should Resistance Train</strong>
    </span>
                </p>
                <p>
                    If all of the benefits of muscle strengthening through resistance training
                    were packed into a pill - there would be a line out the door for people to
                    buy it. However, there are no monetary vested interests or massive profits
                    to be had on the scale of pharmaceuticals or charging insurances to treat
                    illness to pump awareness.
                </p>
                <p>
                    For the vast majority of people, resistance training has gone overlooked.
                    When analyzing nearly 480,000 adults in the United States a whopping 4.5% of
                    them met the guideline of resistance training twice a week. These physical
                    activity guidelines are provided by the World Health Organization and <a href={"https://kirehab.com/blog/why-working-out-is-good-for-you"}>we
                    covered them more here</a> if you’d like to know if you are one of the few that
                    meets the criteria.&nbsp;
                </p>
                <img src={image3} alt={"blog image"}/>
                <p>
                    This is an abysmal stat given the known benefits that are seen with
                    resistance training. If you pair it with regular aerobic exercise, you are
                    in rare company and will set yourself up to live longer with less physical
                    limitations.&nbsp;
                </p>
                <p>The pluses are well established, so let’s talk about them.</p>
                <p>
    <span style={{ textDecoration: "underline" }}>
      <strong>Resisitnance Training Defends Against Chronic Illness</strong>
    </span>
                </p>
                <p>
                    Remember that study I mentioned of like a half a million people? Yeah, they
                    showed that when you pair resistance training with aerobic exercise, you are
                    basically giving your body the tools to give the stiff arm to dying early
                    from chronic illnesses (cardiovascular disease, cancer, chronic lower
                    respiratory tract disease). Though it's not 100% guaranteed, the numbers
                    reveal anywhere from 50%-75% reduced risk of dying from these conditions. I
                    don’t know about you, but I am taking those odds all day everyday.
                </p>
                <p>
                    So what if it is just looking at resistance training? Below is an
                    infographic that was created from a systematic review that just looked at
                    the relationship between resistance training and mortality. Still pretty
                    amazing odds for 60 mins a week!&nbsp;
                </p>
                <img src={image10} alt={"blog image"}/>
                <p>
                    The benefits for resistance training on other specific chronic illnesses
                    have also been studied. Here is a great list of the positive effects listed
                    by illness.&nbsp;
                </p>
                <img src={image5} alt={"blog image"}/>
                <p>
    <span style={{ textDecoration: "underline" }}>
      <strong>Resistance Training Increases Metabolic Efficiency</strong>
    </span>
                </p>
                <p>
                    We tend to forget the important role that muscle plays when regulating our
                    sugar and sugar storage. When you eat carbohydrates, your muscle tissue
                    should store those carbs as glycogen. The two major storage depots for
                    glycogen are your skeletal muscle and your liver.&nbsp;
                </p>
                <img src={image8} alt={"blog image"}/>
                <p>
                    When there is a lack of exercise compounded with a diet steady with surplus
                    calories disorders like insulin resistance (learn more here) can start
                    forcing more storage to the liver which in the long run causes some serious
                    problems (fatty liver, etc). If this metabolic conundrum begins with someone
                    in their 20’s imagine how the development goes once they are in their 40’s.
                    Chronic illness take time and often don’t have associated symptoms until it
                    is MUCH harder to reverse/treat. This is why they are often referred to as
                    “silent killers”.&nbsp;
                </p>
                <p>
                    This is why regular stress on the muscular system in the form of resistance
                    exercise (and even better with aerobic exercise) early and often can
                    literally save your life. The investment in your health is far more valuable
                    than any other you have made - and just like any other investment the best
                    time to start was yesterday and the second best time is now.&nbsp;
                </p>
                <img src={image7} alt={"blog image"}/>
                <p>
                <span style={{ textDecoration: "underline" }}>
      <strong>Resistance Training is the True Fountain of Youth</strong>
    </span></p>
                <img src={image4} alt={"blog image"}/>
                <p>
                    At the age of 40 muscle wasting (sarcopenia) becomes measurable and each
                    decade after it increases. However, we know that in <a href={"https://pubmed.ncbi.nlm.nih.gov/20375740/"}>even just 12 weeks of
                    resistance training the effects of sarcopenia can be REVERSED.</a>&nbsp;
                </p>
                <img src={image1} alt={"blog image"}/>
                <p>
                    This is literally anti-aging. Muscles adapt very well to stress, and it
                    doesn’t seem to matter how old you are. In fact, resistance training may the
                    MOST important for the aging population and could be one of the largest
                    factor to being physically independent for as long as possible when
                    aging.&nbsp;
                </p>
                <p>
                    This is very interesting given most people would be scared to have someone
                    in their 70’s lift weights. However, this is incredibly important to stave
                    off sarcopenia and build stronger bones. Outside of the structural benefits
                    of stronger muscles and bones, lifting heavier objects comes with an
                    increase in coordination and confidence.&nbsp;
                </p>
                <p>
                    This can act like a positive loop where this newfound strength and
                    confidence will push one to get more out of their body doing daily tasks or
                    maybe trying new things with their loved one to provide more value to their
                    lives. The goal is to stay as young as possible for as long as possible. One
                    way to blow out less candles than last year is to add resistance training
                    into your routine if you do not have it in there.&nbsp;
                </p>
                <p>
    <span style={{ textDecoration: "underline" }}>
      <strong>Resistance Training Can Boost Athletic Performance</strong>
    </span>
                </p>
                <p>
                    Take some time to read through the table below. Many athletes, young and
                    old, never get the most out of their performance because they lack sound
                    resistance training. Outside of elite professional athletes - many don’t
                    lift weights. Remember less than 5% of adults lift weights twice a
                    week.&nbsp;
                </p>
                <p>
                    Sports and recreational activities are all about managing forces that are
                    placed upon you efficiently as possible. Some activities like distance
                    running require more efficiency than absolute forces while others like
                    field/court sports require a blend of both.&nbsp;
                </p>
                <p>
                    I would argue that many who play recreational sports do not focus enough on
                    the ability to produce and absorb absolute strength. For example when
                    running we know that we absorb 5-8x our body weight each step we take. Now
                    imagine how much more force is required to cut or change direction like in a
                    field/court sport.
                </p>
                <p>
                    The muscles must be strong enough to handle these forces. Resistance
                    training can prepare your body to withstand these forces to reduce the risks
                    of injury and increase your ability on the field/court.&nbsp;
                </p>
                <img src={image2} alt={"blog image"}/>
                <p>
                <span style={{ textDecoration: "underline" }}>
      <strong>Resistance Training has Psychological Benefits</strong>
    </span>
                </p>
                <p>
                    Confidence is the cornerstone of performance. Athletes like Dieon Sanders
                    embodies this. Resistance training has shown to help with depression and
                    anxiety as well as sleep disorders.&nbsp;
                </p>
                <img src={image6} alt={"blog image"}/>
                <p>
                    It makes a lot of sense when you think about it. Resistance training is all
                    about overcoming a a physically challenging weight and slowly becoming
                    stronger and more efficient at lifting the given weight. It is about making
                    the hard easy.&nbsp;
                </p>
                <p>
                    Outside of the feel good hormones that are released secondary to exercise,
                    becoming stronger gives one more confidence in their body. In my experience,
                    this inherently bleeds into their everyday life. There are much less things
                    they are unable to do socially when friends ask to hang out.&nbsp;
                </p>
                <p>
                    For example, physical activities one may do in a social setting like
                    dancing, hiking, playing recreational sport all become less intimidating
                    after you have challenged your body physically and find out how strong you
                    really can be.&nbsp;
                </p>
                <p>
                    So it is not all about just getting strong when it comes to resistance
                    training. It is much more than that. Not dying, staying young for as long as
                    possible, performing at higher levels, avoiding metabolic illnesses, and
                    being more confident all sound pretty awesome to me. What do you think?
                </p>
            </>

        </BlogPostOld>

    )
}